export const USER_TYPE = {
  REGISTER_USER: 'REGISTER_USER',
  LOGIN_USER: 'LOGIN_USER',
  GET_ALL_USERS: 'GET_ALL_USERS',
  GET_ONE_USER: 'GET_ONE_USER',
};

export const PAYMENT = {
  START_PAYMENT: 'START_PAYMENT',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
  GET_PAYMENT: 'GET_PAYMENT',
};
